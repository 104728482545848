import React from 'react';
import '../style.css';

function History() {
    return (
        <div>
            <p className='title'>학과연혁</p>
                <div className="history-container">
                <div className="text-container">
                    <p>창조적 역량을 펼칠 수 있는 진리의 전당,
                    <br/><b style={{fontSize:'20px'}}>경성대학교</b></p>
                    <p><b style={{color:'#1E3252', fontSize:'18px'}}>오늘보다 내일이 더 기대되는 대학</b>
                    <br/>반세기를 이어온 경성의 발전은 지금도 계속되고 있습니다.</p>
                </div>
                <div>
                    <img src="images/library.jpg" alt="library"/>
                </div>
            </div>
            <img src="images/historyLog.png" alt="Log"/>
        </div>
    );
};

export default History;
