import React, { useState } from 'react';
import '../style.css';

const Table = (props) => {
    const [selectedYear, setSelectedYear] = useState('2024');

    // 선택된 년도에 따라 이미지 변경
    const handleChangeYear = (year) => {
        setSelectedYear(year);
    };

    return (
        <>
            <div>
                <p className='title'>교육과정표</p>
            </div>
            <div className="button-container">
                <button 
                    onClick={() => handleChangeYear('2024')} 
                    className={selectedYear === '2024' ? 'selected' : ''}
                >
                    2024
                </button>
                <button 
                    onClick={() => handleChangeYear('2023')} 
                    className={selectedYear === '2023' ? 'selected' : ''}
                >
                    2023
                </button>
                <button 
                    onClick={() => handleChangeYear('2022')} 
                    className={selectedYear === '2022' ? 'selected' : ''}
                >
                    2022
                </button>
            </div>
            {/* 년도에 따라 이미지 변경 */}
            {selectedYear === '2024' && <img src="images/e-book2024.png" alt="2024"/>}
            {selectedYear === '2023' && <img src="images/e-book2023.png" alt="2023"/>}
            {selectedYear === '2022' && <img src="images/e-book2022.png" alt="2022"/>}
        </>
    );
};

export default Table;
