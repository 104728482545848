import React from 'react';
import { Link } from 'react-router-dom';
import './headerstyle.css';

function Footer() {
    return (
        <div className="footer">
            <div className='additional-link'>
                <Link to="/policy">개인정보처리방침</Link>
                <span>|</span>
                <Link to="/terms">이용약관</Link>
                <span>|</span>
                <Link to="https://kscms.ks.ac.kr/resources/cw/App/ksvr2019/index.html">오시는길</Link>
            </div>
            <p>(48434) 부산 남구 수영로 309 (대연동) 경성대학교 8호관 608호</p>
            <p>TEL: 051-663-5140</p>
            <p style={{color: "#999", fontSize: "13px"}}>© KYUNGSUNG UNIVERSITY SOFTWARE. 2022864024 안근효</p>
        </div>
    );
}

export default Footer;