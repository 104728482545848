import React from 'react';
import '../style.css';

function Goals() {
    return (
        <>
            <div>
                <p className='title'>교육목표 및 인재상</p>
            </div>
            <img src="images/GoalsImage.jpg" alt="Goal"/>
        </>
    );
};

export default Goals;
