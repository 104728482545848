import React from 'react';
import '../style.css';

function Introduction() {
    return (
        <>
            <div>
                <p className='title'>학과소개</p>
            </div>
            <img src="images/Introduction_department.jpg" alt="department"/>
        </>
    );
};

export default Introduction;
