import React from "react";
import "../style.css";

const Policy = (props) => {
  return (
    <>
      <div>
        <p className="title">개인정보처리방침</p>
      </div>
      <div class="article">
        <li>개인정보처리방침(2023-09-13)</li>
        <p>
          경성대학교는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를
          보호하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기
          위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
        </p>
      </div>
      <div>
        <li>개인정보의 처리 목적</li>
        <p style={{textAlign: 'left'}}>
          경성대학교는 개인정보를 다음의 목적을 위해 처리합니다. 처리하고 있는
          개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이
          변경될 시에는 사전 동의를 받아 필요한 조치를 이행할 예정입니다.
        </p>

        <li>회원 가입 및 관리</li>
        <p style={{textAlign: 'left'}}>
          회원제 서비스 제공에 따른 본인확인, 개인 식별, 불량회원의 부정이용
          방지와 비인가 사용방지, 가입의사 확인, 연령확인, 분쟁 조정을 위한 기록
          보존, 불만처리 등 민원처리, 고지사항 전달 등
        </p>

        <li>시 및 학사 업무 처리</li>
        <p style={{textAlign: 'left'}}>
          입시전형 업무, 신입생 선발, 학사관리 및 학교행정 업무, 외국인 유학생
          관리 등
        </p>

        <li>각종 서비스 제공</li>
        <p style={{textAlign: 'left'}}>
          장애학생 지원, 장학금 업무, 도서 대출 반납 및 이용자 관리, 기숙사
          관리, 수강생 학사관리, 취업 지원, 보건관리, 학자금 대출 등
        </p>

        <li>발전기금 기탁자 관리</li>
        <p style={{textAlign: 'left'}}>발전기금 기탁자 관리 및 기부금 영수증 발급 등</p>
      </div>
    </>
  );
};

export default Policy;
