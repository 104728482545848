import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './headerstyle.css';

const Header = (props) => {
    const [isSidebarHovered, setIsSidebarHovered] = useState(false);
    const [isMenuHovered, setIsMenuHovered] = useState(false);
    const [menuContent, setMenuContent] = useState([]);

    // 사이드바 호버 시 메뉴 표시
    const handleSidebarMouseEnter = () => {
        setIsSidebarHovered(true);
    };
    // 사이드바 호버X 시 메뉴 안 보임
    const handleSidebarMouseLeave = () => {
        setIsSidebarHovered(false);
    };

    // 사이드바 메뉴항목 중 하나 or 사이드바+에 호버 시 메뉴 확장
    const handleMenuMouseEnter = (content) => {
        setMenuContent(content);
        setIsMenuHovered(true);
    };
    // 사이드바+에 호버X 시 메뉴 확장
    const handleMenuMouseLeave = () => {
        setIsMenuHovered(false);
    };

    return (
        <div className="header-container">
            <Link to="/" className="header-link">
                <img className="logo" src="images/kyunsungLogo.png" alt="Logo"/>
                <h1>소프트웨어학과</h1>
            </Link>
            <div className="top-bar">
                <Link to="/signin" className="top-link">로그인</Link>
                <p>|</p>
                <Link to="/sitemap" className="top-link">사이트맵</Link>
            </div>
            
            <div className="sidebar">
                <ul>
                    <li>
                        <Link to="/introduction" 
                            className="hoverable-link"
                            onMouseEnter={() => handleMenuMouseEnter([
                                { text: "학과소개", link: "/introduction" },
                                { text: "교육목표 및 인재상", link: "/goals" },
                                { text: "전공역량", link: "/competencies" },
                                { text: "교수진 소개", link: "/faculty" },
                                { text: "학과연혁", link: "/history" }
                            ])}
                            onMouseLeave={handleMenuMouseLeave}>
                            학과소개
                        </Link>
                    </li>
                    <li>
                        <Link to="https://kscms.ks.ac.kr/ipsi/Main.do"
                            className="hoverable-link"
                            onMouseEnter={() => handleMenuMouseEnter([
                                { text: "입시요강", link: "https://kscms.ks.ac.kr/ipsi/Main.do" },
                                { text: "학과적성", link: "https://www.career.go.kr/cnet/front/base/major/FunivMajorView.do?SEQ=578" }
                            ])}
                            onMouseLeave={handleMenuMouseLeave}>
                            입시안내
                        </Link>
                    </li>
                    <li>
                        <Link to="/curriculum"
                            className="hoverable-link"
                            onMouseEnter={() => handleMenuMouseEnter([
                                { text: "이수체계도", link: "/curriculum" },
                                { text: "교육과정표", link: "/table" }
                            ])}
                            onMouseLeave={handleMenuMouseLeave}>
                            교과과정
                        </Link>
                    </li>
                    <li>
                        <Link to="/career"
                            className="hoverable-link"
                            onMouseEnter={() => handleMenuMouseEnter([
                                { text: "학과 취업진로 안내", link: "/career" },
                                { text: "자격증/자격시험", link: "/certification" }
                            ])}
                            onMouseLeave={handleMenuMouseLeave}>
                            취업진로
                        </Link>
                    </li>
                    <li>
                        <Link to="https://kscms.ks.ac.kr/software/CMS/Board/Board.do?mCode=MN032"
                            className="hoverable-link"
                            onMouseEnter={() => handleMenuMouseEnter([
                                { text: "학생활동 포토갤러리", link: "https://kscms.ks.ac.kr/software/CMS/Board/Board.do?mCode=MN032" }
                            ])}
                            onMouseLeave={handleMenuMouseLeave}>
                            학생활동
                        </Link>
                    </li>
                    <li>
                        <Link to="/notice"
                            className="hoverable-link"
                            onMouseEnter={() => handleMenuMouseEnter([
                                { text: "공지사항", link: "/notice" },
                                { text: "자유게시판", link: "/free" }
                            ])}
                            onMouseLeave={handleMenuMouseLeave}>
                            커뮤니티
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="sidebar_plus" 
                 style={{ width: isSidebarHovered || isMenuHovered ? '330px' : '0' }}
                 onMouseEnter={handleSidebarMouseEnter}
                 onMouseLeave={handleSidebarMouseLeave}>
                <ul>
                    {menuContent.map((item, index) => (
                        <li key={index}>
                            <Link to={item.link}>{item.text}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Header;
