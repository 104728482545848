import React from "react";
import "../style.css";

const Terms = (props) => {
  return (
    <>
      <p className="title">이용약관</p>
      <p>이용약관 안내입니다.</p>
      <div className="article">
        <li>제 1장 총칙</li>
        <ui>제 1조 목적</ui>
        <p>
          본 이용약관은 경성대학교 포탈홈페이지(이하 홈페이지)와 이용자(이하
          이용자)간에 경성대학교(이하 학교)에서 제공하는 각종 서비스의 이용에
          관한 여러 가지 사항과 필요한 절차를 규정함을 목적으로 합니다.
        </p>
        <ui>제 2조 약관의 효력 및 변경</ui>
        <p>
          (1) 본 약관은 홈페이지에서 온라인으로 공시함으로써 효력이 발생되고,
          특별한 사유가 발생할 경우 관련법령에 위배되지 않는 범위에서 개정될 수
          있습니다. 개정된 약관은 온라인상에 공시함으로 효력이 발생되고 종전의
          약관은 새 약관으로 대체됩니다.
          <br />
          (2) 약관에 동의하는 것은 정기적으로 홈페이지를 방문하여 약관의
          변경사항을 확인하는 것을 필요로 합니다. 변경된 약관에 대한 정보를 알지
          못해 발생하는 이용자의 피해는 학교에서 책임지지 않습니다.
        </p>
        <li>제 2장 의무 및 책임</li>
        <ui>제 3조 학교의 의무</ui>
        <p>
          (1) 학교는 홈페이지의 운영, 유지에 관련된 법률사항을 준수합니다.
          <br />
          (2) 학교는 이용자가 희망한 서비스를 특별한 사정이 없는 한 제공
          게시일에 서비스를 이용할 수 있도록 합니다.
          <br />
          (3) 학교는 이용자의 개인정보 보호를 위해 보안시스템을 구축하며,
          개인정보 보호정책을 공시하고 준수합니다.
          <br />
          (4) 학교는 이용자로부터 제기되는 의견과 불만을 수용합니다. 제기된
          의견과 불만이 정당하다고 객관적으로 인정되면 적절한 절차를 거쳐 즉시
          처리합니다. 단, 처리가 곤란한 경우 이용자에게 사유를 통보 합니다.
        </p>
        <ui>제 4조 이용자의 의무</ui>
        <p>
          (1) 이용자는 홈페이지의 서비스를 이용함에 있어 요구하는 절차를 성실히
          이행할 것을 동의해야 합니다.
          <br />
          (2) 이용자는 본 약관에서 규정하는 사항과 기타 학교가 정한 제반 규정,
          공지사항 등 이용에 있어서 규칙과 관계법령을 준수해야 합니다.
          <br />
          (3) 이용자는 다음과 같은 행위를 할 경우 그 책임은 이용자에게 있으며
          이로 인하여 발생할 수 있는 모든 민?형사상 책임 또한 이용자에게
          있습니다.
          <br />
          가. 고의로 타인 혹은 집단에게 해를 입히는 행위
          <br />
          나. 음란물, 불법적인 데이터, 텍스트, 음악, 사진 등을 게시하는 행위
          <br />
          다. 해킹, 컴퓨터 바이러스 등의 유포 행위
          <br />
          라. 서비스의 목적에 맞지 않는 선전, 스팸메일, 행운의 편지 등 불필요한
          컨텐츠를 게시하는 행위
          <br />
          마. 관련 법령 또는 계약에 의하여 그 전송 및 게시가 금지된 정보 또는
          콘텐츠의 게시와 전송행위
          <br />
          바. 다른 사용자의 개인정보를 수집, 저장하고 이를 악용하는 행위
          <br />
          사. 전기통신사업법, 저작권법 등 관련법령에 위배되는 불법적이고 부당한
          행위
          <br />
          아. 기타 선량한 풍속 및 사회정서에 반하는 행위
          <br />
          (4) 이용자는 상업적인 목적으로 본 홈페이지에 있는 내용을 임의로 이용할
          수 없습니다.
          <br />
        </p>
        <li>제 3장 서비스의 이용</li>
        <ui>제 5조 컨텐츠 관련 조항</ui>
        <p>
          (1) 학교에서 제공하는 서비스와 그에 필요한 제작 소프트웨어, 이미지,
          마크, 로고, 디자인, 서비스명칭, 정보 및 상품과 관련된 지적재산권과
          기타권리는 학교에 있습니다.
          <br />
          (2) 이용자는 학교가 승인한 경우를 제외하고 전항의 소정의 각 재산들에
          대한 전부 또는 일부의 수정, 대여, 판매 등 상업적인 행위를 할 수
          없으며, 3자로 하여금 이와같은 행위를 하도록 허락할 수 없습니다.
          <br />
          (3) 이용자가 게시한 게시물 등 서비스와 관련한 이용자의 자료들에 대한
          권리와 책임은 이용자에게 있고, 이로 인하여 발생할 수 있는 모든
          민,형사상 책임 또한 이용자에게 있으며 이에 대해 학교는 어떠한 책임도
          지지 않습니다.
          <br />
          (4) 학교는 이용자가 제공한 정보를 적절한 항목으로 재분류할 수
          있습니다.
          <br />
          (5) 외부에서 링크된 자료는 그 출처를 정확하게 밝혀야 합니다.
          <br />
          (6) 학교는 이용약관에 위배되는 불법적인 컨텐츠를 삭제할 수 있습니다.
        </p>
        <ui>제 6조 서비스의 이용 시간</ui>
        <p>
          (1) 본 홈페이지는 학교의 업무 혹은 기술상 특별한 지장이 없는 한
          연중무휴 1일 24시간 운영을 원칙으로 합니다. 단 시스템의 점검이나 증설
          및 교체 등의 기술적인 이유로 서비스를 일시 중단할 수 있으며 이 경우
          사전에 공지합니다.
          <br />
          (2) 학교는 홈페이지의 운영이 국가비상사태, 천재지변, 정전, 서비스
          설비의 장애 혹은 사용자의 폭주로 정상적인 서비스의 제공이 불가능해질
          경우 서비스의 전부 또는 일부를 중지할 수 있습니다. 단 이 경우 그
          사유와 기간을 사전, 사후에 공지합니다.
        </p>
        <li>제 4장 개인정보</li>
        <ui>제 7조 개인정보의 보호</ui>
        <p>
          (1) 학교의 이용자 개인정보 보호는 관련 법령 및 학교가 정하는 '개인정보
          보호정책'에 정한 바에 의합니다. 개인정보 보호정책은 특별한 목적에 따라
          갱신될 수 있으며 이를 공지함을 원칙으로 합니다. 이용자는 홈페이지 내에
          있는 ‘개인정보 보호정책’ 페이지를 언제든지 열람하여 이를 확인할 수
          있습니다.
          <br />
          (2) 본 홈페이지에 포함된 링크 또는 배너를 클릭하여 다른 웹페이지로
          옮겨갈 경우 개인정보 보호방침은 그 사이트의 운영기관에서 게시하는
          새로운 방침을 적용받게 됩니다.
          <br />
          (2) 이용자는 홈페이지의 서비스를 이용하기 위해 필요한 인증절차 중에
          타인에게 정보가 노출되지 않도록 주의를 기울여야 합니다.
          <br />
          (3) 이용자의 부주의와 관리소홀로 노출된 개인정보로 인해 발생하는 모든
          결과에 대한 책임은 이용자에게 있습니다.
        </p>
        <li>제 5장 이용제한 및 제재</li>
        <ui>제 8조 이용제한 및 제재</ui>
        <p>
          이용자가 특정 서비스의 이용과 관련하여 게재하거나 작성한 컨텐츠에 대해
          다음과 같이 필요하다고 판단되는 경우 이용자의 동의 없이 삭제할 수
          있고, 그로인한 문제의 심각성에 따라 이용자에게 경고 및 이용자 아이디
          사용 정지 또는 해지처리 할 수 있습니다.
          <br />
          (1) 광고 게재 등 서비스 내용과 관련이 없는 경우
          <br />
          (2) 공공질서 및 미풍양속을 위반하거나 저해한다고 판단되는 경우
          <br />
          (3) 다른 사람이나 단체의 지적재산권을 침해하는 것을 비롯하여 범죄와
          관련된 경우
          <br />
          (4) 이용자가 이용계약을 해지하였을 경우
          <br />
          (5) 기타 학교가 정해놓은 별도 운영기준 및 관계 법령 등에 위반된다고
          판단되는 경우
        </p>
        <li>제 6장 기타</li>
        <ui>제 9조 재판권 및 준거법</ui>
        <p>
          (1) 본 서비스의 이용과 관련하여 분쟁이 발생할 경우 소송은 학교
          소재지인 부산지역의 관할법원에 제기합니다.
          <br />
          (2) 분쟁을 해결하기 위한 법률절차는 한국법을 적용합니다.
        </p>
        <ui>제 10조 불만사항 및 침해사고 피해발생시 처리절차</ui>
        <p>
          이용자가 학교홈페이지를 통해 제공되는 서비스를 이용하던 중
          불만사항이나 개인정보 침해사고 피해가 발생할 경우에는 학교 홈페이지
          관리자에게 메일로 통보하며, 학교는 최대한 단기간 내에 적절한 조치를
          취합니다.
        </p>
      </div>
    </>
  );
};

export default Terms;
