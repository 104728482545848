import React, { useEffect, useRef, useState } from "react";
import "./mainstyle.css";

const images = [
  "url('/images/back.jpg')",
  "url('/images/back(1).jpg')",
  "url('/images/back(2).jpg')",
  "url('/images/back(3).jpg')",
  "url('/images/back(4).jpg')",
];

const Main = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const intervalRef = useRef(null);

  // 5초마다 자동으로 이미지 변경
  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true);
      }, 1000);
    }, 5000);
  };

  // 인터벌 함수 마운트/언마운트
  useEffect(() => {
    startInterval();
    return () => clearInterval(intervalRef.current);
  }, []);

  //버튼을 누르면 이미지 변경
  const goToImage = (index) => {
    if (!fade) {    // 이미지 변경 중에는 클릭 무시
      return;
    }
    setFade(false);
    clearInterval(intervalRef.current);
    setTimeout(() => {
      setCurrentImageIndex(index);
      setFade(true);
      startInterval();
    }, 1000);
  };

  return (
    <div className="image-container">
      <div
        className={`image ${fade ? "fade-in" : "fade-out"}`}
        style={{ backgroundImage: images[currentImageIndex] }}
      ></div>
      <div className="image next-image"></div>

      <div className="text-container">
        <div className="text">
          <h1>Department of Software and Computer Engineering</h1>
          <h2>세계와 소통하는 코드, 혁신을 만드는 힘</h2>
          <h3>경성대학교 소프트웨어학과</h3>
        </div>
      </div>

      <div className="main-button-container">
        {[...Array(images.length).keys()].map((index) => (
          <button
            key={index}
            className="circle-button"
            onClick={() => goToImage(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Main;
