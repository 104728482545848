import React from "react";
import { Link } from "react-router-dom";
import "../style.css";

function Career() {
  return (
    <>
      <p className="title">학과 취업진로 안내</p>
      <p style={{ maxWidth: "800px", margin: "0 auto", textAlign: "center" }}>
        본 학과 졸업생의 주요 진로는 웹 및 앱 개발자, 게임 개발자,
        서버/네트워크/데이터베이스/보안 관리자, 전산직 공무원, 프리랜서
        프로그래머 등을 포함하며, IT 분야 대기업, 중견 및 중소 업체와 금융권 및
        일반 기업체 IT 부서, 전산학 관련 국내외 대학원 등으로 진출할 수 있으며
        스타트업 창업이 용이한 장점이 있습니다.
      </p>

      <p style={{ margin: "50px 0 0 0", textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>
        취업 준비를 위한 주요 경진대회, 멘토링 제도, 공모전 목록
      </p>
      <div className="career-container">
        <Link to="http://icpckorea.org">
          ACM-ICPC (한국 대학생 프로그래밍 경시대회)
        </Link>
        <br />
        <Link to="https://research.samsung.com/scpc">
          SCPC (삼성 대학생 프로그래밍 경진대회)
        </Link>
        <br />
        <Link to="https://www.hanium.or.kr/">
          한이음/프로보노/이브와 ICT멘토링
        </Link>
        <br />
        <Link to="https://www.oss.kr/dev_competition">
          공개SW개발자대회
        </Link>
      </div>
    </>
  );
}

export default Career;
