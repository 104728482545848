import React from 'react';
import '../style.css';

const Curriculum = (props) => {
    return (
        <>
            <div>
                <p className='title'>이수체계도</p>
            </div>
            <img src="images/hydrographic_system.png" alt="hydrographic_system"/>
        </>
    );
};

export default Curriculum;
