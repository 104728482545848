import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../style.css";

const Notice = () => {
  const [notices, setNotices] = useState([]);
  const [newNoticeTitle, setNewNoticeTitle] = useState("");
  const [newNoticeLink, setNewNoticeLink] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    const initialNotices = [
      {
        id: 1,
        title: "제33회 사회복지법인 사랑의날개 장학지원사업 신청안내(6.10~7.10.)",
        link: "https://kscms.ks.ac.kr/software/CMS/Board/Board.do?mCode=MN033&mode=view&mgr_seq=30&board_seq=167237",
      },
      {
        id: 2,
        title: "[필독] 2024-1학기 성적우수마일리지장학금 수혜조건 및 추진일정 안내",
        link: "https://kscms.ks.ac.kr/software/CMS/Board/Board.do?mCode=MN033&mode=view&mgr_seq=30&board_seq=164012",
      },
      {
        id: 3,
        title: "2024-1학기 교내장학(경성2유형장학금) 지급 안내 (6/12 예정)",
        link: "https://kscms.ks.ac.kr/software/CMS/Board/Board.do?mCode=MN033&mode=view&mgr_seq=30&board_seq=167168",
      },
    ];
    setNotices(initialNotices);
  }, []);

  // 공지사항 추가
  const handleAddNotice = () => {
    if (newNoticeTitle.trim() && newNoticeLink.trim()) {
      setNotices([
        ...notices,
        { id: notices.length + 1, title: newNoticeTitle, link: newNoticeLink },
      ]);
      setNewNoticeTitle("");
      setNewNoticeLink("");
    }
  };

  // 공지사항 삭제
  const handleDeleteNotice = (id) => {
    setNotices(notices.filter((notice) => notice.id !== id));
  };

  // 관리자모드/사용자모드 전환
  const toggleAdminMode = () => {
    setIsAdmin(!isAdmin);
    setIsPasswordCorrect(false);
    setPassword("");
  };

  // 비밀번호 입력 필드 값 변경
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // 입력된 비밀번호 검증
  const handlePasswordSubmit = () => {
    if (password === "0410") {
      setIsPasswordCorrect(true);
    } else {
      alert("비밀번호가 올바르지 않습니다.");
    }
  };

  return (
    <div>
      <div className="header">
        <p className="title">공지사항</p>
      </div>
      <div className="admin-mode">
        <button onClick={toggleAdminMode}>
          {isAdmin ? "사용자 모드" : "관리자 모드"}
        </button>
        {isAdmin && !isPasswordCorrect && (
          <div className="password-container">
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="비밀번호"
              className="password-input"
            />
            <button onClick={handlePasswordSubmit} className="submit-password-button">
              확인
            </button>
          </div>
        )}
      </div>

      <div className="notice-board">
        <ul>
          {notices.map((notice) => (
            <li key={notice.id} className="notice-item">
              <div className="notice-header">
                <Link
                  to={notice.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="notice-title"
                >
                  {notice.title}
                </Link>

                {isPasswordCorrect && (
                  <button
                    onClick={() => handleDeleteNotice(notice.id)}
                    className="delete-button"
                  >
                    삭제
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>

        {isPasswordCorrect && (
          <div className="add-notice-container">
            <div className="add-notice">
              <input
                type="text"
                value={newNoticeTitle}
                onChange={(e) => setNewNoticeTitle(e.target.value)}
                placeholder="공지사항 제목"
              />
              <input
                type="text"
                value={newNoticeLink}
                onChange={(e) => setNewNoticeLink(e.target.value)}
                placeholder="공지사항 링크"
              />
            </div>
            <button onClick={handleAddNotice} className="add-button">
              추가
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notice;
