import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const SiteMap = (props) => {
  return (
    <>
      <div>
        <p className="title">사이트맵</p>
      </div>

      <div>
        <ul>
          <li className="sitemap">
            <p>학과소개</p>
            <ul className="sitemap-container">
              <li>
                <Link to="/introduction">학과소개</Link>
              </li>
              <li>
                <Link to="/goals">교육목표 및 인재상</Link>
              </li>
              <li>
                <Link to="/competencies">전공역량</Link>
              </li>
              <li>
                <Link to="/faculty">교수진 소개</Link>
              </li>
              <li>
                <Link to="/history">학과연혁</Link>
              </li>
            </ul>
          </li>
          <li className="sitemap">
            <p>입시안내</p>
            <ul className="sitemap-container">
              <li>
                <Link to="https://kscms.ks.ac.kr/ipsi/Main.do">입시요강</Link>
              </li>
              <li>
                <Link to="https://www.career.go.kr/cnet/front/base/major/FunivMajorView.do?SEQ=578">
                  학과적성
                </Link>
              </li>
            </ul>
          </li>
          <li className="sitemap">
            <p>교과과정</p>
            <ul className="sitemap-container">
              <li>
                <Link to="/Curriculum">이수체계도</Link>
              </li>
              <li>
                <Link to="/table">교육과정표</Link>
              </li>
            </ul>
          </li>
          <li className="sitemap">
            <p>취업진로</p>
            <ul className="sitemap-container">
              <li>
                <Link to="/career">학과 취업진로 안내</Link>
              </li>
              <li>
                <Link to="/certification">자격증/자격시험</Link>
              </li>
            </ul>
          </li>
          <li className="sitemap">
            <p>학생활동</p>
            <ul className="sitemap-container">
              <li>
                <Link to="https://kscms.ks.ac.kr/software/CMS/Board/Board.do?mCode=MN032">
                  학생활동 포토갤러리
                </Link>
              </li>
            </ul>
          </li>
          <li className="sitemap">
            <p>커뮤니티</p>
            <ul className="sitemap-container">
              <li>
                <Link to="/notice">공지사항</Link>
              </li>
              <li>
                <Link to="/free">자유게시판</Link>
              </li>
            </ul>
          </li>
          <li className="sitemap">
            <p>회원서비스</p>
            <ul className="sitemap-container">
              <li>
                <Link to="/signin">로그인</Link>
              </li>
              <li>
                <Link to="/find-idpw">아이디/비밀번호 찾기</Link>
              </li>
              <li>
                <Link to="/signup">회원가입</Link>
              </li>
            </ul>
          </li>
          <li className="sitemap">
            <p>기타</p>
            <ul className="sitemap-container">
              <li>
                <Link to="/policy">개인정보취급방침</Link>
              </li>
              <li>
                <Link to="/terms">이용약관</Link>
              </li>
              <li>
                <Link to="https://kscms.ks.ac.kr/resources/cw/App/ksvr2019/index.html">오시는길</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SiteMap;
