import React, { useState, useEffect } from "react";
import "../style.css";

const Free = () => {
  const [notices, setNotices] = useState([]);
  const [newNoticeTitle, setNewNoticeTitle] = useState("");
  const [newNoticeBody, setNewNoticeBody] = useState("");
  const [newNoticePassword, setNewNoticePassword] = useState("");
  const [newNoticeAuthor, setNewNoticeAuthor] = useState("");
  const [deletePassword, setDeletePassword] = useState("");
  const [expandedNoticeId, setExpandedNoticeId] = useState(null);
  const [deletePasswordVisible, setDeletePasswordVisible] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState({});

  useEffect(() => {
    const initialNotices = [
      {
        id: 1,
        title: "[필독] 게시판 이용 원칙",
        body: "게시판을 아름답게 사용합시다! ^_^",
        password: "0410",
        author: "관리자",
      },
      {
        id: 2,
        title: "게시판 이용 방법",
        body: "잘 사용하시면 됩니다.",
        password: "0410",
        author: "관리자",
      },
      {
        id: 3,
        title: "댓글 이용",
        body: "댓글은 익명입니다. ",
        password: "0410",
        author: "관리자",
      },
    ];
    setNotices(initialNotices);
    const initialComments = {}; // 댓글 상태 초기화
    initialNotices.forEach((notice) => {
      initialComments[notice.id] = [];
    });
    setComments(initialComments);
  }, []);

  // 게시글 추가
  const handleAddNotice = () => {
    if (
      newNoticeTitle.trim() &&
      newNoticeBody.trim() &&
      newNoticePassword.trim() &&
      newNoticeAuthor.trim()
    ) {
      setNotices([
        ...notices,
        {
          id: notices.length + 1,
          title: newNoticeTitle,
          body: newNoticeBody,
          password: newNoticePassword,
          author: newNoticeAuthor,
        },
      ]);
      setNewNoticeTitle("");
      setNewNoticeBody("");
      setNewNoticePassword("");
      setNewNoticeAuthor("");
      setComments({ ...comments, [notices.length + 1]: [] }); // 새 게시글 댓글 상태 초기화
    }
  };

  // 게시글 삭제
  const handleDeleteNotice = (id, password) => {
    const noticeToDelete = notices.find((notice) => notice.id === id);
    if (noticeToDelete.password === password) {
      setNotices(notices.filter((notice) => notice.id !== id));
      setDeletePassword("");
      setDeletePasswordVisible(false);
    } else {
      alert("비밀번호가 일치하지 않습니다.");
    }
  };

  // 게시글 타이틀 누르면 확대/축소 - 본문 내용/댓글 표시
  const toggleExpandNotice = (id) => {
    setExpandedNoticeId(expandedNoticeId === id ? null : id);
  };

  // 삭제 버튼 누르면 비밀번호 입력창 오픈
  const toggleDeletePasswordVisibility = (id) => {
    setDeletePasswordVisible(!deletePasswordVisible);
  };

  // 게시글 댓글 달기
  const handleAddComment = (id) => {
    if (commentText.trim()) {
      const newComment = { id: comments[id].length + 1, text: commentText };
      setComments({ ...comments, [id]: [...comments[id], newComment] });
      setCommentText("");
    }
  };

  return (
    <div>
      <p className="title">자유게시판</p>
      <div className="notice-board">
        <ul>
          {notices.map((notice) => (
            <li key={notice.id} className="notice-item">
              <div className="notice-header">
                <div
                  onClick={() => toggleExpandNotice(notice.id)}
                  className="notice-title"
                >
                  {notice.title}
                </div>
                <div className="author">{notice.author}</div>{" "}
                <button
                  onClick={() => toggleDeletePasswordVisibility(notice.id)}
                  className="delete-button"
                >
                  삭제
                </button>
              </div>

              {deletePasswordVisible && expandedNoticeId === notice.id && (
                <div className="password-container">
                  <input
                    type="password"
                    value={deletePassword}
                    onChange={(e) => setDeletePassword(e.target.value)}
                    placeholder="비밀번호 입력"
                    className="password-input"
                  />
                  <button
                    onClick={() =>
                      handleDeleteNotice(notice.id, deletePassword)
                    }
                    className="submit-password-button"
                  >
                    확인
                  </button>
                </div>
              )}

              {expandedNoticeId === notice.id && (
                <div>
                  <div className="notice-body">{notice.body}</div>
                  <div>
                    <ul>
                      {comments[notice.id].map((comment) => (
                        <li className="comment" key={comment.id}>
                          {comment.text}
                        </li>
                      ))}
                    </ul>
                    <input
                      className="password-input"
                      type="text"
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                      placeholder="댓글을 입력하세요"
                    />
                    <button
                      className="submit-password-button"
                      onClick={() => handleAddComment(notice.id)}
                    >
                      답글
                    </button>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
        <div className="add-notice-container">
          <div className="add-notice">
            <div className="nickname-password">
              <input
                type="text"
                value={newNoticeAuthor}
                onChange={(e) => setNewNoticeAuthor(e.target.value)}
                placeholder="닉네임"
              />
              <input
                type="password"
                value={newNoticePassword}
                onChange={(e) => setNewNoticePassword(e.target.value)}
                placeholder="게시글 비밀번호"
              />
            </div>
            <input
              type="text"
              value={newNoticeTitle}
              onChange={(e) => setNewNoticeTitle(e.target.value)}
              placeholder="제목"
            />
            <textarea
              value={newNoticeBody}
              onChange={(e) => setNewNoticeBody(e.target.value)}
              placeholder="내용을 입력하세요"
            />
          </div>
          <button onClick={handleAddNotice} className="add-button">
            추가
          </button>
        </div>
      </div>
    </div>
  );
};

export default Free;
