import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './jsCode/Header';
import Main from './jsCode/Main';
import SignIn from './jsCode/login/SignIn';
import SignUp from './jsCode/login/SignUp';
import SiteMap from './jsCode/SiteMap';

import Introduction from './jsCode/Introduce/Introduction';
import Goals from './jsCode/Introduce/Goals';
import Competencies from './jsCode/Introduce/Competencies';
import Faculty from './jsCode/Introduce/Faculty';
import History from './jsCode/Introduce/History';

import Curriculum from './jsCode/Curriculums/Curriculum';
import Table from './jsCode/Curriculums/Table';

import Career from './jsCode/Careers/Career';
import Certification from './jsCode/Careers/Certification';

import Notice from './jsCode/Community/Notice';
import Free from './jsCode/Community/Free';

import Footer from './jsCode/Footer';
import Policy from './jsCode/FooterBar/Policy';
import Terms from './jsCode/FooterBar/Terms';

function App() {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/sitemap" element={<SiteMap />} />

                <Route path="/introduction" element={<Introduction />} />
                <Route path="/goals" element={<Goals />} />
                <Route path="/competencies" element={<Competencies />} />
                <Route path="/faculty" element={<Faculty />} />
                <Route path="/history" element={<History />} />

                <Route path="/curriculum" element={<Curriculum />} />
                <Route path="/table" element={<Table />} />

                <Route path="/career" element={<Career />} />
                <Route path="/certification" element={<Certification />} />

                <Route path="/notice" element={<Notice />} />
                <Route path="/free" element={<Free />} />

                <Route path="/policy" element={<Policy />} />
                <Route path="/terms" element={<Terms />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
