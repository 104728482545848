import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../style.css";

const SignIn = (props) => {
  const navigate = useNavigate();

  // 로그인 폼 제출 이벤트 처리
  const handleLogin = (event) => {
    event.preventDefault();
    alert("로그인이 완료되었습니다.");
    navigate("/"); // 메인화면으로
  };

  return (
    <>
      <p className="title">로그인</p>
      <p>
        회원서비스를 이용하시려면 로그인이 필요합니다.
        <br />
        아이디와 비밀번호를 입력 후 로그인 하십시오.
      </p>
      <div className="login-container">
        <form onSubmit={handleLogin} className="login-form">
          <div className="input-group">
            <input
              type="text"
              id="username"
              name="username"
              placeholder="아이디를 입력해주세요"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              id="password"
              name="password"
              placeholder="비밀번호를 입력해주세요"
              required
            />
          </div>
          <button type="submit" className="button">
            로그인
          </button>
        </form>
        <div className="additional-options">
          <Link to="/signup">회원가입</Link>
          <span>|</span>
          <Link to="/find-username">아이디 찾기</Link>
          <span>|</span>
          <Link to="/find-password">비밀번호 찾기</Link>
        </div>
      </div>
      <p style={{ fontSize: "15px" }}>
        5회 로그인 실패 시 계정상태가 잠금상태가 되며, 비밀번호 찾기를 이용하여
        비밀번호를 변경하시면 잠금상태가 해제됩니다.
      </p>
    </>
  );
};

export default SignIn;
