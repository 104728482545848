import React from 'react';
import '../style.css';

function Competencies() {
    return (
        <>
            <div>
                <p className='title'>전공역량</p>
            </div>
            <img src="images/CompetenciesImage.jpg" alt="Competencies"/>
        </>
    );
};

export default Competencies;
