import React from 'react';
import '../style.css';

function Faculty() {
    return (
        <>
            <div>
                <p className='title'>교수진 소개</p>
            </div>
            <div className='faculty-list'>
                <div className='faculty-member'>
                    <h3>양태천 교수</h3>
                    <p>email: tcyang@ks.ac.kr</p>
                </div>
                <div className='faculty-member'>
                    <h3>성낙운 교수</h3>
                    <p>email: nuseong@ks.ac.kr</p>
                </div>
                <div className='faculty-member'>
                    <h3>홍석희 교수</h3>
                    <p>email: shong@@ks.ac.kr</p>
                </div>
                <div className='faculty-member'>
                    <h3>지상문 교수</h3>
                    <p>email: smchiks@ks.ac.kr</p>
                </div>
                <div className='faculty-member'>
                    <h3>강인수 교수</h3>
                    <p>email: dbaisk@ks.ac.kr</p>
                </div>
                <div className='faculty-member'>
                    <h3>김주현 교수</h3>
                    <p>email: isadora@naver.com</p>
                </div>
                <div className='faculty-member'>
                    <h3>최동한 교수</h3>
                    <p>email: livedong@daum.net</p>
                </div>
                <div className='faculty-member'>
                    <h3>정문주 교수</h3>
                    <p>email: mjlunar@ks.ac.kr</p>
                </div>
            </div>
        </>
    );
};

export default Faculty;
