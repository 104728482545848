import React from 'react';
import '../style.css';

function Certification() {
    return (
        <>
            <div>
                <p className='title'>자격증/자격시험</p>
                <p>주요 자격증 안내</p>
            </div>
            <img src="images/certificate.jpg" alt="Goal"/>
        </>
    );
};

export default Certification;
