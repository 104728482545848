import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../style.css';

const SignUp = () => {
    const navigate = useNavigate();
    // 회원가입 폼 기본 양식
    const [formData, setFormData] = useState({
        username: '',
        id: '',
        password: '',
        confirmPassword: '',
    });

    // 회원가입 폼 입력 이벤트 처리
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,    // 상태 변수에 입력값 업데이트
            [name]: value,
        });
    };

    // 회원가입 폼 제출 이벤트 처리
    const handleSubmit = (e) => {
        e.preventDefault();

        const { username, id, password, confirmPassword } = formData;

        if (password !== confirmPassword) {
            alert('비밀번호가 일치하지 않습니다.');
            setFormData({
                ...formData,
                password: '',
                confirmPassword: '',
            });
            return;
        }

        console.log('사용자 이름:', username);
        console.log('아이디:', id);

        alert('회원 가입이 완료되었습니다.');
        // 다시 초기화
        setFormData({
            username: '',
            id: '',
            password: '',
            confirmPassword: '',
        });

        navigate('/signin');
    };

    return (
        <>
            <p className="title">회원가입</p>
            <div className="login-container">
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="input-group">
                        <input
                            type="text"
                            name="username"
                            id="username"
                            placeholder="이름을 입력해주세요"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="text"
                            name="id"
                            id="id"
                            placeholder="아이디를 입력해주세요"
                            value={formData.id}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="비밀번호를 입력해주세요"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="비밀번호를 다시 입력해주세요"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="button">
                        회원 가입
                    </button>
                </form>
                <div className='additional-options'>
                    <Link to="/signin">로그인</Link>
                </div>
            </div>
        </>
    );
};

export default SignUp;
